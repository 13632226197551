<template>
  <div class="import-status text-body-2">
    <span v-if="status === $options.importStatuses.ERROR" class="error--text">
      {{ $t('import_status.error') }}
      <button class="error--text" @click="download">{{ errorDocumentName }}</button>
    </span>

    <span v-else-if="status === $options.importStatuses.IN_PROCESS" class="import-status__in-process">
      <v-progress-circular size="13" width="1" indeterminate />
      {{ $t('import_status.process') }}
    </span>

    <import-status-stub v-else />
  </div>
</template>

<script>
// Constants
import { ERROR, IN_PROCESS } from '@/constants/importStatuses';

// Services
import mediaService from '@/services/media';

// Utils
import { download } from '@/utils/common';

// Http
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';

// Components
import ImportStatusStub from '@/components/ImportStatusStub.vue';

export default {
  name: 'ImportStatus',

  components: { ImportStatusStub },

  props: {
    status: { type: String, default: '' },
    url: { type: String, default: '' },
    errorDocumentName: { type: String, default: '' },
    getStatus: { type: Function, required: true },
  },

  methods: {
    async download() {
      const status = await this.getStatus();

      if (status === IN_PROCESS) {
        this.$emit('show-modal');
      }

      const blob = await mediaService.getPrivateMediaBlob(getBaseUrlWithoutApi() + this.url);
      download(this.errorDocumentName, blob);
    },
  },

  importStatuses: {
    ERROR,
    IN_PROCESS,
  },
};
</script>

<style lang="scss">
.import-status {
  &__in-process {
    color: rgba($--black-color-0, 0.5);
  }
}
</style>
