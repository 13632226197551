<template>
  <div class="import-status-stub" v-bind="$attrs"></div>
</template>

<script>
export default {
  name: 'ImportStatusStub',
};
</script>

<style lang="scss">
.import-status-stub {
  height: 20px;
}
</style>
